import './Progress.css'

const Progress = (props) => {
  const cancelledStatus = 100;
  let value = 0;
  switch(props.status) {
    case 20:
      value = 5;
      break;
    case 30:
      value = 40;
      break;
    case 40:
      value = 70;
      break;
    case 50:
      value = 100;
      break;
  }

  return (
    <>
      <section>
          <div className="accordion">
            {props.status != cancelledStatus &&
            <div className="steps">
              <progress className="onboardingProgress" value={value} max="100"></progress>
              <div className="step-item">
                  <button className="step-button text-center" type="button" data-toggle="collapse"
                      data-target="#collapseOne" aria-expanded={props.status >= 20} aria-controls="collapseOne">
                      1
                  </button>
                  <div className="step-title">
                      Application in Progress
                  </div>
              </div>
              <div className="step-item">
                  <button className="step-button text-center collapsed" type="button" data-toggle="collapse"
                      data-target="#collapseTwo" aria-expanded={props.status >= 30} aria-controls="collapseTwo">
                      2
                  </button>
                  <div className="step-title">
                      Client ID/Secret Emailed
                  </div>
              </div>
              <div className="step-item">
                  <button className="step-button text-center collapsed" type="button" data-toggle="collapse"
                      data-target="#collapseThree" aria-expanded={props.status >= 40} aria-controls="collapseThree">
                      3
                  </button>
                  <div className="step-title">
                      Installation Started
                  </div>
              </div>
              <div className="step-item">
                  <button className="step-button text-center collapsed" type="button" data-toggle="collapse"
                      data-target="#collapseThree" aria-expanded={props.status >= 50} aria-controls="collapseThree">
                      4
                  </button>
                  <div className="step-title">
                      Complete
                  </div>
              </div>
            </div>}

            {props.status == cancelledStatus &&
            <div className="steps" style={{justifyContent: 'center'}}>
              <progress className="onboardingProgress" value={0} max="100"></progress>
              <div className="step-item">
                  <button className="step-button text-center" style={{backgroundColor: 'gray'}} type="button"
                      aria-expanded={true} >
                  </button>
                  <div className="step-title">
                      Cancelled
                  </div>
              </div>
            </div>
            }
          </div>
      </section>
    </>
  )
}

export default Progress;
