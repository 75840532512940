import { useEffect, useState } from 'react'
import axios from 'axios'
import { getAuthHeader, REACT_APP_PS_PORTAL_API_URI } from 'authHelper'
import { useNavigate } from 'react-router-dom'
import { useMsal } from '@azure/msal-react'
import { Spinner } from 'react-bootstrap'
import moment from 'moment'
import TableContainer from '../table/TableContainer'

const PracticeList = () => {
    const navigate = useNavigate()
    // We start with an empty list of items.
    const [loading, setLoading] = useState(true)
    const [items, setItems] = useState([])
    const { instance, accounts } = useMsal()
    const columns = [
        {
            Header: "Practice ID",
            accessor: "id"
        },
        {
            Header: "Practice Name",
            accessor: "name",
        },
        {
            Header: "Abbreviation",
            accessor: "abbreviation"
        },
        {
            Header: "Software",
            accessor: "software.name"
        },
        {
            Header: "Onboarding Status",
            accessor: "onboardingStatus.description"
        },
        {
            Header: "App Client ID",
            accessor: "appClientId",
        },
        {
            Header: "Secret Expiry",
            accessor: "secretExpiry",
        },
        {
            Header: "First Run Completed",
            accessor: "firstRunCompleted"
        }
    ]

    async function loadPractices() {
        const authHeader = await getAuthHeader(instance, accounts)
        const result = await axios.get(`${REACT_APP_PS_PORTAL_API_URI}/practices`, {
            headers: authHeader
        })
        const records = result.data.map((record) => {
            if (record.status === 'firstRun') {
                return {
                    ...record,
                    status: 'Not Started',
                    firstRunCompleted: record.firstRunCompleted ? moment(record.firstRunCompleted).format('DD/MM/yyyy') : 'N/A',
                    secretExpiry: record.secretExpiry ? moment(record.secretExpiry).format('DD/MM/yyyy') : 'N/A'
                }
            }
            else {
                return {
                    ...record,
                    firstRunCompleted: record.firstRunCompleted ? moment(record.firstRunCompleted).format('DD/MM/yyyy') : 'N/A',
                    secretExpiry: record.secretExpiry ? moment(record.secretExpiry).format('DD/MM/yyyy') : 'N/A'
                };
            }
        });
        setItems(records)
        setLoading(false)
    }

    // https://stackoverflow.com/questions/60618844/react-hooks-useeffect-is-called-twice-even-if-an-empty-array-is-used-as-an-ar
    // React.Strict mode is on
    useEffect(() => {
        loadPractices()
    }, [])

    const navigateUser = async (practiceId) => {
        navigate(practiceId.toString())
    }

    return (
        <>
            {loading && <Spinner animation="border" variant="secondary" />}
            {!loading &&
                <TableContainer columns={columns} data={items} onClick={navigateUser} />
            }
        </>
    )
}

export default PracticeList
